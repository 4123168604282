import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import * as yup from 'yup';
import { GridContainer } from 'components/Ui/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PaperProps,
  requiredField,
  categorySuccess,
  characterLimit,
} from 'constants';
import { setCategoryColor, setCategoryName } from './store/categoryAdminSlice';
import {
  postCategoryAdmin,
  putCategoryAdmin,
} from './services/categoryAdminApi';
import {
  getDialogHeading,
  renderSaveCancelButtons,
  fieldValidation,
  useNotification,
  onSave,
  refinePayload,
} from 'utils/commonUtils';

const categoryAdminSchema = yup.object().shape({
  name: fieldValidation(characterLimit),
  color: yup.string().required(requiredField),
});

const CategoryAdminDialog = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [loadingButton, setLoadingButton] = useState(false);
  const { open, setOpen, fetchData, selectCalendarId } = props;
  const calendarCategory = useSelector((state) => state.CategoryAdminSlice);
  const { newCategory, category, color, name, categoryId } =
    calendarCategory || {};

  const defaultValue = {
    category: category,
    color: color,
    name: name,
    categoryId: categoryId,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(categoryAdminSchema),
  });
  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.category,
    defaultValue.color,
    defaultValue.name,
    defaultValue.categoryId,
  ]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValue);
  };

  const fetchCategories = () => {
    setTimeout(() => {
      fetchData();
    }, 500);
  };

  const onSubmit = async () => {
    const payload = {
      color: color,
      name: name,
      categoryId: newCategory ? undefined : categoryId,
    };
    await onSave(
      categorySuccess,
      newCategory,
      postCategoryAdmin,
      putCategoryAdmin,
      refinePayload(payload),
      selectCalendarId,
      fetchCategories,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton,
      selectCalendarId
    );
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Category', handleClose)}
        <GridContainer container>
          <FormInputText
            name="name"
            type="text"
            control={control}
            label="Category"
            helperText={errors?.name?.message}
            errors={!!errors.name}
            register={register('name', {
              onChange: (e) => dispatch(setCategoryName(e.target.value)),
            })}
          />

          <FormInputText
            name="color"
            type="color"
            control={control}
            label="Color"
            helperText={errors?.color?.message}
            errors={!!errors.color}
            register={register('color', {
              onChange: (e) => dispatch(setCategoryColor(e.target.value)),
            })}
          />
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridContainer>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default CategoryAdminDialog;
