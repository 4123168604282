import { DateErrorTypography } from 'components/Ui/styled';

export const ANNOUNCEMENT_BASE_URL_APPEND = 'announce/';
export const STUDENTS_BASE_URL_APPEND = 'students/profileV0/';
export const SEARCH_STUDENTS_BASE_URL_APPEND = 'students/search?q=';
export const STUDENT_ADDITIONAL_INFO_BASE_URL_APPEND = 'students/profile/';
export const MY_PROFILE_BASE_URL_APPEND = 'profile/';
export const ASSET_EXPORT_URL = 'export';
export const TASK_MANAGEMENT_BASE_URL_APPEND = 'task-mgmt/';
export const ONLINE_ATTENDANCE_AUDIT_BASE_URL_APPEND =
  'online-attendance/audit/';
export const ATTENDANCE_AUDIT_BASE_URL_APPEND = 'audit-mgmt/';
export const SYSTEM_CONFIGURATION_BASE_URL_APPEND = 'config/';
export const DOCUMENT_MANAGEMENT_BASE_URL_APPEND = 'docs-mgmt/';
export const DOCUMENTS_BASE_URL_APPEND = 'docs/';
export const USER_BASE_URL_APPEND = 'user-mgmt/users/';
export const STUDENT_PROFILE_FILTER_OPTION_BASE_URL_APPEND =
  'students/profile/';
export const TRACKERS_URL = 'trackers';
export const PUBLIC_PROFILE_URL = 'public-profiles/';
export const REPORT_TOOL_URL = 'report-tool/';
export const schoolErrorMessage = 'An error occurred while fetching Schools';

export const categoryList = [
  {
    color: '#83FF33',
    category: 'Holiday',
  },
  {
    color: '#33F6FF',
    category: 'School Holiday',
  },
  {
    color: '#F933FF',
    category: 'Important Dates ',
  },
];

export const autoHideDurationTime = 3000;
export const noneOption = { id: '', value: 'None' };

export const PaperProps = {
  sx: {
    maxWidth: 'unset',
    width: '400px',
    maxHeight: 'auto',
  },
};

export const EventPaperProps = {
  sx: {
    maxWidth: 'unset',
    width: '400px',
    maxHeight: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
  },
};

export const userCountPaperProps = {
  sx: {
    maxWidth: 'unset',
    width: '700px',
    maxHeight: 'unset',
  },
};

export const PaperPropsWithoutScrollbar = {
  sx: {
    maxWidth: 'unset',
    width: '800px',
    maxHeight: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
  },
};

export const PaperPropsCheck = {
  sx: {
    maxWidth: 'unset',
    width: '600px',
    maxHeight: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
  },
};

export const DataGridDialog = {
  sx: {
    maxWidth: 'unset',
    width: '1300px',
    maxHeight: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
  },
};

export const DialogStyle = {
  sx: {
    maxWidth: 'unset',
    width: '1250px',
    height: '100vh',
    '&::-webkit-scrollbar': { display: 'none' },
  },
};

export const TimeOutDialog = {
  sx: {
    maxWidth: 'unset',
    width: '613px',
    maxHeight: 'auto',
  },
};

export const emptyGroup = [
  {
    groupName: '',
    description: '',
    isSystemGroup: '',
  },
];

export const deltaAcademyCalendar = '73f5e80855ea424983110f0b055e8f0a';
export const deltaAcademyCalendarErrorMessage = `As this calendar is linked to dashboard calendar so it can't be deleted.`;
export const rowsPerPage = [50, 100, 250];
export const rowsPerPage2 = [500, 1000, 2000];
export const intialPageSize = 50;
export const intialPageSize2 = 500;
export const characterLimit = 100;
export const updateGroupMessage = 'System groups may not be updated';
export const notificationHeader = 'Notification';
export const dateFnsFormat = 'MM-dd-yyyy';
export const dateFormat = 'YYYY-MM-DD';
export const yearMonthFormat = 'MM-yyyy';
export const requiredLimit = `This field should not exceed ${characterLimit} characters`;
export const requiredField = (
  <span style={{ color: '#F44335' }}>This field is required</span>
);
export const requiredTextLimit = (
  <span style={{ color: '#F44335' }}>
    This field should not exceed 4000 characters
  </span>
);

const errorTypography = (errorMessage) => (
  <DateErrorTypography variant="base">{errorMessage}</DateErrorTypography>
);
export const maxDateError = (
  <DateErrorTypography variant="base">
    Verified date must be greater than acquired date
  </DateErrorTypography>
);

export const minDateError = (
  <DateErrorTypography variant="base">
    Only future dates are allowed
  </DateErrorTypography>
);

export const validDateError = (
  <DateErrorTypography variant="base">
    Please enter a valid date
  </DateErrorTypography>
);

export const disableFutureDate = errorTypography('Future dates are not allowed.');
export const timeInterval = 5;
export const assetSuccess = 'Successfully the asset is saved';
export const assetTypeSuccess = 'Successfully the asset type is saved';
export const locationSuccess = 'Successfully the location info is saved';
export const locationTypeSuccess = 'Successfully the location type is saved';
export const buildingSuccess = 'Successfully the building info is saved';
export const fundingTypeSuccess = 'Successfully the funding type is saved';
export const calendarSuccess = 'Successfully the calendar is saved';
export const announcementSuccess = 'Successfully the announcement is saved';
export const eventSuccess = 'Event added successfully';
export const eventUpdateSuccess = 'Event updated successfully';
export const groupSuccess = 'Successfully the group is saved';
export const groupMembersSaved = 'Assigned successfully';
export const groupMembersDeleted = 'Un-assigned successfully';
export const userSuccess = 'Successfully user is saved';
export const permissionSuccess = 'Successfully the permission is saved';
export const noSpace = 'Spaces are not allowed';
export const taskSuccess = 'Successfully the task is saved';
export const taskComplete = 'Successfully the task is marked';
export const watchSuccess = 'Successfully the watch is saved';
export const pasteSuccess = 'Successfully the cell is pasted';
export const onlineAttendanceSuccess =
  'Successfully the online attendance is saved';
export const resendInvitationSuccess =
  'Successfully the Invitation has been sent';
export const gloalPermissionSuccess =
  'Successfully the global permission is saved';
export const categorySuccess = 'Successfully the category saved';
export const noneAccessErrorMessage = `As the main calendar and permission calendar are same so it can't have none access`;
export const massUpdateSuccess =
  'Successfully the bulk update of assets are completed';
export const assetTagError = (tag) => `The asset tag "${tag}" is not found`;
export const attendanceAuditArchiveSuccess =
  'Successfully attendance audit is archived';
export const attendanceAuditUnArchiveSuccess =
  'Successfully attendance audit is unarchived';
export const replaceAccessSuccess = 'Successfully existing access are replaced';
export const attendanceAuditSuccess =
  'Successfully the attendance audit is saved';
export const systemConfigurationSuccess =
  'Successfully system configuration is saved';
export const emptyValueError = `Can't add empty values`;
export const duplicatesError = 'Duplicates are not allowed';
export const sharedFolderSuccess = 'Successfully shared folder is saved';
export const sharedFolderArchiveSuccess =
  'Successfully shared folder is archived';
export const sharedFolderUnArchiveSuccess =
  'Successfully shared folder is unarchived';
export const sharedFolderAccessSuccess =
  'Successfully shared folder access is saved';
export const resetDashboardPasswordSuccess =
  'Successfully the reset password has been sent';
export const syncDashboardPasswordToCanvasSuccess =
  'Successfully the dashboard password has been synced to canvas';
export const updateAssetsConformation =
  'Are you sure you want to update all listed assets ?';
export const resetDashboardPasswordConformation =
  'Are you sure you want to reset dashboard password ?';
export const syncDashboardPasswordToCanvasConformation =
  'Are you sure you want to sync dashboard password to canvas ?';
export const teacherLastContactedBy = `Last Contacted By: (Teacher Name) on (Date and Time) by (Email/Phone)`;
export const teacherLastUpdatedBy = ` Notes: Last Updated by (Teacher Name) on (Date and Time)`;
export const teacherNotes = `Here will be the notes section where teachers can update any information that may be incorrect from the information listed above`;
export const emptyStudentsError =
  'Please add at least one student (Press the Enter button after selecting the student ID field)';
export const noInfoLocations = `No Locations are currently assigned`;
export const noInfoAssets = `No Assets are currently assigned`;
export const noInfoTasks = `No Tasks are currently assigned`;
export const textMessage = 'This is a test message';
export const actCategorie = 'ACT';
export const mapsCategorie = 'MAPS';
export const filterSchoolYear = 'School Year';
export const emptyContainer = 'no-info-container';
export const unWatchSchoolStudentContentOne =
  'The selected student is being watched by school ID';
export const unWatchSchoolStudentContentTwo =
  'so once you un-watch this student then all students releated this school will be un-watched. Are you sure you want to proceed ?';
export const trackerSuccess = 'Successfully tracker is saved';
export const renameTrackerColumnSuccess =
  'Successfully tracker column name has been renamed';
export const reorderTrackerColumnSuccess =
  'Successfully tracker column has been reordered';
export const columnNameError = 'Column names should be unique.';
export const reorderColumnSuccess = 'Successfully column is saved';
export const columnHideSuccess = 'The column has been successfully hidden';
export const columnUnHideSuccess = 'The column has been successfully unhidden';
export const statusSuccess = 'Successfully status is saved';
export const tagsSuccess = 'Successfully tags are saved';
export const reportExecutionSuccess = 'Successfully the report execution is saved';

//Permission Messages

export const canManageStudentTrackersPermissionMessage =
  'This action requires a missing permission: CAN_MANAGE_STUDENT_TRACKERS';

export const canWriteStudentTrackerDataPermissionMessage =
  'This action requires a missing permission: CAN_WRITE_STUDENT_TRACKER_DATA';

export const permissionReferenceData = [
  {
    id: 'none',
    value: 'None',
  },
  {
    id: 'admin',
    value: 'Admin',
  },
  {
    id: 'write',
    value: 'Write',
  },
  {
    id: 'read',
    value: 'Read',
  },
];

export const deleteStyle = {
  fontWeight: 'bold',
  cursor: 'pointer',
  position: 'absolute',
  right: '15px',
};

export const categoryCard = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const filterByValues = [
  {
    id: '',
    value: 'None',
  },
  {
    id: 'type',
    value: 'Type',
  },
  {
    id: 'location',
    value: 'Location',
  },
  {
    id: 'assetTag',
    value: 'Asset Tag',
  },
];

export const emptyValues = [
  {
    id: '',
    value: '',
  },
];

export const documentTypeOptions = [
  {
    id: 1,
    value: 'Teaching Credential',
  },
  {
    id: 2,
    value: 'Games Credential',
  },
];

export const attributeOptions = [
  {
    id: 1,
    value: 'Institution',
  },
  {
    id: 2,
    value: 'Credential Name',
  },
];

export const attributeTypeOptions = [
  {
    id: 1,
    value: 'String',
  },
  {
    id: 2,
    value: 'Number',
  },
  {
    id: 3,
    value: 'Date',
  },
  {
    id: 4,
    value: 'Boolean',
  },
];

export const yesNoOptions = [
  {
    id: '',
    value: 'None',
  },
  {
    id: true,
    value: 'Yes',
  },
  {
    id: false,
    value: 'No',
  },
];

export const booleanOptions = [
  {
    id: true,
    value: 'Yes',
  },
  {
    id: false,
    value: 'No',
  },
];

export const specificTypeOftask = [
  {
    id: 'user',
    value: 'User',
  },
  {
    id: 'supervisor',
    value: 'Supervisor',
  },
  {
    id: 'system',
    value: 'System',
  },
  {
    id: 'showAll',
    value: 'Show All',
  },
];

export const assignUserType = [
  {
    id: 'user',
    value: 'User',
  },
  {
    id: 'student',
    value: 'Student',
  },
];

export const assetAttributes = [
  {
    id: 'none',
    value: 'None',
  },
  {
    id: 'typeId',
    value: 'Asset Type',
  },
  {
    id: 'fundingId',
    value: 'Funding Type',
  },
  {
    id: 'locationId',
    value: 'Location',
  },
  // {
  //   id: 'buildingId',
  //   value: 'Building Type',
  // },
  {
    id: 'serial',
    value: 'Serial',
  },
  {
    id: 'acquired',
    value: 'Date Acquired and Verified',
  },
  // {
  //   id: 'verified',
  //   value: 'Date Verified',
  // },
  {
    id: 'manu',
    value: 'Manufacturer',
  },
  {
    id: 'model',
    value: 'Model',
  },
  {
    id: 'macAddress',
    value: 'Mac Address',
  },
  {
    id: 'ipAddress',
    value: 'Ip Address',
  },
];

export const accessToOptions = [
  {
    id: 'user',
    value: 'User',
  },
  {
    id: 'group',
    value: 'Group',
  },
];

export const accessLevelOptions = [
  {
    id: 'none',
    value: 'None',
  },
  {
    id: 'read',
    value: 'Read',
  },
  {
    id: 'write',
    value: 'Write',
  },
];

export const calendarPermissionReferenceData = [
  {
    id: 'admin',
    value: 'Admin',
  },
  {
    id: 'write',
    value: 'Write',
  },
  {
    id: 'read',
    value: 'Read',
  },
];

export const testOption = [{ id: 'maps', value: 'MAPS' }];

export const companyOptions = [
  {
    id: 'ies',
    value: 'IES',
  },
  {
    id: 'delta',
    value: 'Delta',
  },
  {
    id: 'k3',
    value: 'K3',
  },
];

export const dialogDefaultProps = {
  disableRestoreFocus: true,
};

export const editIconTestId = 'edit-icon';
export const deleteIconTestId = 'delete-icon';

export const reportOptions = [
  {
    id: 'none',
    value: 'None',
  },
  {
    id: 'active_students_report',
    value: 'Active Students Report',
  },
  {
    id: 'student_attendance_records',
    value: 'Student Attendance Records',
  },
  {
    id: 'test_report',
    value: 'Test Report',
  },
];

export const studentTypeOptions = [
  {
    id: 'Day',
    value: 'Day',
  },
  {
    id: 'GCSA',
    value: 'GCSA',
  },
  {
    id: 'None',
    value: 'None',
  },
  {
    id: 'Online',
    value: 'Online',
  },
  {
    id: 'RAPA',
    value: 'RAPA',
  },
  {
    id: 'CE',
    value: 'CE',
  }
];
