import Tooltip from '@mui/material/Tooltip';
import { ActionWrapperBox } from 'components/Common/common.styled';
import {
  ButtonIcon,
  IconArticle,
  EditActionIcon,
  RoundedIconArchive,
  RoundedIconUnarchive,
} from 'components/Ui/styled';

export const renderSharedFolderAction = (
  params,
  handleUpdateSharedFolders,
  handleSharedFolderAccess,
  handleArchiveSharedFolders,
  handleUnarchiveSharedFolders
) => {
  return (
    <ActionWrapperBox>
      {!params?.row?.archived ? (
        <>
          <ButtonIcon onClick={() => handleUpdateSharedFolders(params)}>
            <EditActionIcon />
          </ButtonIcon>
          <ButtonIcon onClick={() => handleSharedFolderAccess(params)}>
            <Tooltip title="Folder access" arrow={false} placement="top">
              <IconArticle />
            </Tooltip>
          </ButtonIcon>
          <ButtonIcon onClick={() => handleArchiveSharedFolders(params)}>
            <Tooltip title="Archive" arrow={false} placement="top">
              <RoundedIconArchive />
            </Tooltip>
          </ButtonIcon>
        </>
      ) : (
        <ButtonIcon onClick={() => handleUnarchiveSharedFolders(params)}>
          <Tooltip title="Un-Archive" arrow={false} placement="top">
            <RoundedIconUnarchive />
          </Tooltip>
        </ButtonIcon>
      )}
    </ActionWrapperBox>
  );
};
