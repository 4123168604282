import { DataGridPro } from '@mui/x-data-grid-pro';
import { TypographyLink } from 'components/Ui/styled';
import { rowsPerPage } from 'constants';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { getRowClassName, getRowHeight, formatDate } from 'utils/commonUtils';
import Typography from '@mui/material/Typography';

export const getStudentsDataGrid = (
  studentList,
  columns,
  handelPageChange,
  handleOnPageSizeChange
) => {
  const { rows, page, total, pageSize, isLoading } = studentList;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      page={page - 1}
      rowCount={total}
      rowsPerPageOptions={rowsPerPage}
      pagination
      paginationMode="server"
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      onPageChange={(newPage) => handelPageChange(newPage)}
      loading={isLoading}
      hideFooterPagination={isLoading}
      onPageSizeChange={(newPageSize) => handleOnPageSizeChange(newPageSize)}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
    />
  );
};

export const studentColumns = (handleStudentRedirect, handleStudentCourses) => [
  {
    headerName: 'Student ID',
    field: 'studentNumber',
    width: 150,
    renderCell: (params) => {
      const studentNumber = params.row.studentNumber;
      return (
        <TypographyLink
          variant="h6"
          data-testid="studentIdValue"
          onClick={() => handleStudentRedirect(params.row.id)}
        >
          {studentNumber}
        </TypographyLink>
      );
    },
  },
  {
    headerName: 'First Name',
    field: 'firstName',
    width: 150,
    renderCell: (params) => {
      const studentName = params.row.firstName;
      return <Typography variant="base">{studentName}</Typography>;
    },
  },

  {
    headerName: 'Last Name',
    field: 'lastName',
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const studentName = params.row.lastName;
      return <Typography variant="base">{studentName}</Typography>;
    },
  },
  { headerName: 'Grade', field: 'gradeLevel', width: 150 },
  {
    headerName: 'Courses',
    field: 'courses',
    align: 'center',
    width: 150,
    renderCell: (params) => {
      const courses = params.row.courses;
      return (
        <TypographyLink
          variant="h6"
          onClick={() => handleStudentCourses(params)}
        >
          {courses}
        </TypographyLink>
      );
    },
  },
  { headerName: 'Student Type', field: 'studentType', width: 150 },
  {
    headerName: 'Home Primary Language',
    field: 'homePrimaryLanguage',
    width: 150,
  },
  { headerName: 'Race Ethnicity', field: 'raceEthnicity', width: 150 },
  {
    headerName: 'Date of Birth',
    field: 'dateOfBirth',
    type: 'date',
    width: 150,
  },
  { headerName: 'isActive', field: 'isActive', type: 'boolean', width: 150 },
  {
    headerName: 'Last Active Semester',
    field: 'lastActiveSemester',
    width: 150,
  },
  {
    headerName: 'Date Last Active',
    field: 'dateLastActive',
    type: 'date',
    width: 150,
  },
  {
    headerName: 'Has Iep Plan',
    field: 'hasIepPlan',
    type: 'boolean',
    width: 150,
  },
  { headerName: 'Iep Teacher', field: 'iepTeacher', width: 150 },
  {
    headerName: 'Note',
    field: 'note',
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: 'Note Updated At',
    field: 'noteUpdatedAt',
    type: 'date',
    width: 150,
  },
  {
    headerName: 'Note Updated By',
    field: 'noteUpdatedBy',
    width: 150,
    renderCell: renderCellExpand,
  },
  { headerName: 'Created At', field: 'createdAt', type: 'date', width: 150 },
  { headerName: 'Updated At', field: 'updatedAt', type: 'date', width: 150 },
  { headerName: 'Version', field: 'version', width: 150 },
];

export const getStudentProfileListData = (studentProfileListData) => {
  const items = studentProfileListData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      resourceId,
      version,
      lastName,
      firstName,
      studentNumber,
      gradeLevel,
      studentType,
      courses,
      homePrimaryLanguage,
      raceEthnicity,
      gender,
      dateOfBirth,
      isActive,
      lastActiveSemester,
      dateLastActive,
      hasIepPlan,
      iepTeacher,
      note,
      noteUpdatedAt,
      noteUpdatedBy,
      createdAt,
      updatedAt,
    }) => ({
      id: resourceId,
      resourceId,
      version,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      studentNumber,
      gradeLevel,
      studentType,
      courses: courses.length,
      coursesData: courses,
      homePrimaryLanguage,
      raceEthnicity,
      gender,
      dateOfBirth: formatDate(dateOfBirth),
      isActive,
      lastActiveSemester: lastActiveSemester,
      dateLastActive: dateLastActive,
      hasIepPlan,
      iepTeacher,
      note,
      noteUpdatedAt: formatDate(noteUpdatedAt),
      noteUpdatedBy,
      createdAt: formatDate(createdAt),
      updatedAt: formatDate(updatedAt),
    })
  );
};
