import { formatDate } from 'utils/commonUtils';

export const getAttendanceAuditVersionsListData = (attendanceAuditsData) => {
  const items = attendanceAuditsData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item, index) => ({
    ...item,
    id: index,
    apiId: item.id,
    name: item.name,
    reportType: item.reportParams.reportType,
    students: item.reportParams.studentIds?.length,
    studentList: item.reportParams.studentIds,
    accessUsers: item.accessUsers?.length,
    accessUsersList: item.accessUsers,
    createdBy: item.createdBy,
    createdAt: formatDate(item.createdAt),
    version: item.version,
    semesters: item.reportParams.semesters?.length,
    semesterList: item.reportParams.semesters,
    modifiedBy: item.modifiedBy,
    modifiedAt: formatDate(item.modifiedAt),
    archived: item.archived,
  }));
};
