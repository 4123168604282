import Tooltip from '@mui/material/Tooltip';
import { SchoolGrades } from 'layouts/applications/attendance/common/constants';
import { AttendanceWrapperBox } from 'layouts/applications/attendance/Attendance.styled';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridWrapper } from 'layouts/applications/attendance/Attendance.styled';
import { useState } from 'react';

const GradeDataTable = (props) => {
  const apiRef = useGridApiRef();
  const { gradeTableData: tableData } = props;
  const [sortModel, setSortModel] = useState([
    { field: 'lastName', sort: 'asc' },
  ]);

  const pinnedColumns = {
    left: ['studentId', 'lastName', 'firstName'],
  };

  const handleSortModelChange = (newModel) => {
    if (newModel.length === 0) {
      setSortModel([{ field: 'lastName', sort: 'asc' }]);
      return;
    }
    const clickedField = newModel[0].field;
    const currentField = sortModel[0]?.field;
    const currentSort = sortModel[0]?.sort;
    const newSort =
      clickedField === currentField && currentSort === 'asc' ? 'desc' : 'asc';
    setSortModel([{ field: clickedField, sort: newSort }]);
  };

  return (
    <GridWrapper>
      <DataGridPro
        apiRef={apiRef}
        rows={tableData.rows}
        columns={tableData.columns}
        rowHeight={48}
        isRowSelectable={() => false}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        initialState={{
          pinnedColumns: pinnedColumns,
        }}
      />
    </GridWrapper>
  );
};

const TabPanel = (props) => {
  const { children, tabValue, gradeIndex, tabIndex, gradeTableData, ...other } =
    props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== tabIndex}
      id={`simple-tabpanel-${tabIndex}`}
      aria-labelledby={`simple-tab-${tabIndex}`}
      {...other}
    >
      {tabValue === tabIndex && (
        <AttendanceWrapperBox>
          <GradeDataTable gradeTableData={gradeTableData}></GradeDataTable>
        </AttendanceWrapperBox>
      )}
    </div>
  );
};

export const generateTrackerTabPanel = (tabValue, gradeTableData) => {
  return SchoolGrades.map(({ gradeIndex, tabIndex }) => {
    if (tabValue === tabIndex) {
      return (
        <TabPanel
          key={tabIndex}
          tabValue={tabValue}
          gradeIndex={gradeIndex}
          tabIndex={tabIndex}
          gradeTableData={gradeTableData}
        />
      );
    }
  });
};

export const renderStudentTrackerCell = (params, item) => {
  const studentTrackerStatus = params?.row?.data?.[item];
  const note = params?.row?.data?.[item]?.note;
  return (
    <Tooltip title={note}>
      <span>{studentTrackerStatus?.status}</span>
    </Tooltip>
  );
};

export const getStudentTrackerCellClass = (params, item) => {
  const itemData = params?.row?.data?.[item];
  const { checked } = itemData || {};

  if (checked) {
    return 'super-app-green';
  }
  return 'super-app-other';
};

export const getHideUnHideColumns = (columns, hiddenColumns) => {
  return columns.map((column) => ({
    columnName: column,
    hide: hiddenColumns.includes(column),
  }));
};
