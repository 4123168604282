import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { GridForm } from 'components/Ui/styled';
import Grid from '@mui/material/Grid';
import { PaperProps } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  DropdownInputList,
  onSave,
  emptyFunction,
} from 'utils/commonUtils';
import { replaceAccessSuccess } from 'constants';
import { putReplaceAccess } from 'layouts/auditManagement/services/attendanceAuditsApi';
import {
  setReplaceAccessUsers,
  resetReplaceAccess,
} from 'layouts/auditManagement/store/replaceAccessSlice/replaceAccessSlice';

const ReplaceAccessDialog = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const {
    open,
    setOpen,
    attendanceAuditId,
    setAttendanceAuditId,
    fetchAttendanceAudits,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);

  const availableUsersList = useSelector((state) => state.availableUsersList);

  const replaceAccessForm = useSelector((state) => state.replaceAccess);
  const { replaceAccessUsers } = replaceAccessForm || {};

  const defaultValue = {
    replaceAccessUsers: replaceAccessUsers,
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
    //resolver: yupResolver(inputSchema),
  });

  const { handleSubmit, reset, control, register } = useFunction;
  //const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.replaceAccessUsers]);

  const availableUsersData = availableUsersList?.map((item) => ({
    id: item.username,
    value: item.username,
  }));

  const handleClose = () => {
    setOpen(false);
    setAttendanceAuditId(null);
    reset(defaultValue);
    dispatch(resetReplaceAccess());
  };

  const handleReplaceAccessChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(
      setReplaceAccessUsers(
        typeof value === 'string' ? value.split(',') : value
      )
    );
  };

  const onSubmit = async () => {
    const payload = {
      usernames: replaceAccessUsers,
    };
    onSave(
      replaceAccessSuccess,
      false,
      emptyFunction,
      putReplaceAccess,
      payload,
      attendanceAuditId,
      fetchAttendanceAudits,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading(
          'Replace existing access of attendance audit',
          handleClose
        )}
        <GridForm container spacing={2}>
          <Grid item xs={12}></Grid>
          <FormInputDropdown
            name='replaceAccessUsers'
            type='text'
            control={control}
            multiple={true}
            checked={defaultValue.replaceAccessUsers}
            renderValue={(selected) => (
              <DropdownInputList selectedValues={selected} />
            )}
            options={availableUsersData}
            label='Replace Access'
            register={register('replaceAccessUsers', {
              onChange: (e) => handleReplaceAccessChange(e),
            })}
          />
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default ReplaceAccessDialog;
