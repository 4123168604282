import React from 'react';
import { GridWrapper } from './Attendance.styled';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';

const GradeDataTable = (props) => {
  const apiRef = useGridApiRef();
  const {
    gradeTableData: tableData,
    isAttendanceAudit,
    handleCellClick,
    handleKeyDown,
  } = props;

  const pinnedColumns = {
    left: isAttendanceAudit
      ? ['name']
      : ['watch', 'lastName', 'firstName', 'tags'],
  };

  return (
    <GridWrapper onKeyDown={handleKeyDown} tabIndex={0}>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rows={tableData.rows}
        columns={tableData.columns}
        isRowSelectable={() => false}
        onCellClick={handleCellClick}
        initialState={{
          pinnedColumns: pinnedColumns,
        }}
      />
    </GridWrapper>
  );
};

export default GradeDataTable;
