import React from 'react';
import Grid from '@mui/material/Grid';
import { NotesTypography } from 'layouts/inventory/Inventory.styled';
import {
  AttendanceNotesWrapper,
  AttendanceNotesList,
  AttendanceNotesTitle,
} from './Attendance.styled';

const NotesHistory = (props) => {
  const { notesData } = props;

  return (
    <>
      {notesData?.noteHistory?.map((item, index) => {
        const { inCompliance, label, addedAt, addedBy, note } = item;
        const noteDate = new Date(addedAt);
        const notesHistory = [
          {
            title: 'Label:',
            description: label,
          },
          {
            title: 'Note:',
            description: note,
          },
          {
            title: 'Added At:',
            description: noteDate.toLocaleString('en-US'),
          },
          {
            title: 'Added By:',
            description: addedBy,
          },
          {
            title: 'In Compliance:',
            description: inCompliance ? 'True' : 'False',
          },
        ];
        return (
          <AttendanceNotesWrapper container key={index}>
            <Grid item xs={12}>
              {notesHistory.map((item, index) => {
                return (
                  <AttendanceNotesList key={index}>
                    <AttendanceNotesTitle variant="h6">
                      {item.title}
                    </AttendanceNotesTitle>
                    <NotesTypography variant="h6">
                      {item.description}
                    </NotesTypography>
                  </AttendanceNotesList>
                );
              })}
            </Grid>
          </AttendanceNotesWrapper>
        );
      })}
    </>
  );
};
export default NotesHistory;
