import Tooltip from '@mui/material/Tooltip';
import replace from 'lodash/replace';
import { statusExtender, SchoolGrades, gradeValues } from '../common/constants';
import StudentDeleteConfirm from '../StudentDeleteConfirm';
import AttendanceTagCellStack from '../AttendanceTagCellStack';
import AttendanceTagCell from '../AttendanceTagCell';
import { dynamicSort } from 'utils/commonUtils';
import TabPanel from '../TabPanel';
import Tab from '@mui/material/Tab';

export const getAttendanceFieldIdentifier = (attendanceField) => {
  return replace(attendanceField, statusExtender, '');
};

export const getTabSchoolGrade = (tabSelected) => {
  const currentSchoolGrade = SchoolGrades.find(
    (SchoolGrade) => SchoolGrade.tabIndex === tabSelected
  );

  return currentSchoolGrade;
};

export const getGradeRows = (rows, currentSchoolGrade) => {
  const { gradeIndex: selectedGrade } = currentSchoolGrade;
  const gradeRows = rows.filter(
    // selectedGrade === 13 is the drop tab selected, if SchoolGrades changes, need to change the number from 13 to defined value
    ({ grade, drop }) =>
      (selectedGrade === 13 && drop === '1') ||
      (grade == selectedGrade && drop !== '1')
  );
  return gradeRows;
};

export const getSortedWeek = (weeks) => {
  weeks.sort((a, b) => {
    return a.week - b.week;
  });
};

export const getGridCellClass = (params) => {
  const { field } = params;
  const exactWeek = field.split('-', 1);

  const getInCompliance = () => {
    const getNotesDetails = params?.row?.notes?.find(
      (item) => item.week === exactWeek[0]
    );
    return getNotesDetails?.inCompliance;
  };

  const inCompliance = getInCompliance();

  const getNotesDetails = () => {
    return params?.row?.notes?.find((item) => item.week === exactWeek[0]);
  };

  const noteDetails = getNotesDetails();

  if (noteDetails?.label === 'Not Enrolled') {
    return 'super-app-not-enrolled';
  }

  if (inCompliance && params.value) {
    return 'super-app-green';
  } else if (!inCompliance && params.value) {
    return 'super-app-red';
  } else {
    return 'super-app-other';
  }
};

export const getCurrentWeekStartAndEndDate = () => {
  // In Delta attendance starts from Friday to Thursday, Hence added 5
  const curr = new Date();
  const firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() + 5)));
  const lastday = new Date(
    firstday.setDate(curr.getDate() - curr.getDay() + 11)
  );

  // 8/8 to 8/12
  const weekIdentifier =
    firstday.getMonth() +
    1 +
    '/' +
    firstday.getDate() +
    ' to ' +
    (lastday.getMonth() + 1) +
    '/' +
    lastday.getDate();

  return weekIdentifier;
};

export const renderAttendanceCell = (params, descr) => {
  var note;
  if (params.row.notes.find(({ week }) => week === descr)) {
    const noteObj = params.row.notes.find(({ week }) => week === descr);
    const noteDate = new Date(noteObj.addedAt);
    note =
      noteObj.addedBy +
      ' - ' +
      noteDate.toLocaleString('en-US') +
      '\n' +
      noteObj.note;
  }
  const toolTipContent = note; //params.row[descr + noteExtender];
  return (
    <Tooltip
      title={<span style={{ whiteSpace: 'pre-line' }}>{toolTipContent}</span>}
    >
      <span className="table-cell-trucate">{params.value}</span>
    </Tooltip>
  );
};

export const renderAttendanceActionCell = (params, setIsDeleteStudent) => {
  const { row } = params;
  const { semester, studentId } = row;

  return (
    <StudentDeleteConfirm
      semester={semester}
      studentId={studentId}
      setIsDeleteStudent={setIsDeleteStudent}
      row={row}
    />
  );
};

export const renderTagsCell = (params) => {
  const tags = params.row.tags;
  return <AttendanceTagCellStack tags={tags} />;
};

export const getGradeValue = (tabIndex) => {
  return gradeValues[tabIndex];
};

export const renderAttendanceTagCells = (
  attendanceTags,
  selectedAttendanceTags
) => {
  const sortedAttendanceTags = attendanceTags.sort(dynamicSort('key'));
  const sortedTags = selectedAttendanceTags.slice().sort();
  let tagLastIndex = sortedTags.length - 1;

  return sortedAttendanceTags.map(({ key, value }) => {
    let isLastIndex = false;

    const currentTagIndex = sortedTags.indexOf(key);
    if (tagLastIndex === currentTagIndex) {
      isLastIndex = true;
    }

    if (sortedTags.includes(key)) {
      return (
        <AttendanceTagCell
          key={key}
          tagLabel={value}
          isLastIndex={isLastIndex}
        ></AttendanceTagCell>
      );
    }
  });
};

export const a11yAttendanceProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const generateAttendanceTabs = () => {
  return SchoolGrades.map(({ gradeName, tabIndex }) => {
    return (
      <Tab
        key={tabIndex}
        label={gradeName}
        {...a11yAttendanceProps(tabIndex)}
      />
    );
  });
};

export const generateAttendanceTabPanel = (
  tabValue,
  gradeTableData,
  attendanceWeeks,
  isAttendanceAudit,
  handleCellClick,
  handleKeyDown
) => {
  return SchoolGrades.map(({ gradeIndex, tabIndex }) => {
    if (tabValue === tabIndex) {
      return (
        <TabPanel
          key={tabIndex}
          tabValue={tabValue}
          gradeIndex={gradeIndex}
          tabIndex={tabIndex}
          gradeTableData={gradeTableData}
          weeks={attendanceWeeks}
          isAttendanceAudit={isAttendanceAudit}
          handleCellClick={handleCellClick}
          handleKeyDown={handleKeyDown}
        />
      );
    }
  });
};
