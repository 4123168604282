import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { intialPageSize } from 'constants';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getButtonSpinner,
  getDataGridHeading,
  useNotification,
  onRedirect,
  showLoader,
  getOptionLabel,
} from 'utils/commonUtils';
import { getStudentProfileListData, studentColumns } from './StudentsDataGrid';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { GridWrapper } from 'components/Ui/styled';
import {
  getStudentsProfile,
  getStudentProfileFilterOptions,
} from 'layouts/studentManagement/services/studentProfileApi';
import {
  setStudentProfileFilterOptions,
  resetStudentProfileFilterOptions,
} from 'layouts/studentManagement/store/studentProfileFilterOptionsSlice/studentProfileFilterOptionsSlice';
import {
  setDataGridCounts,
  resetDataGridCounts,
} from 'utils/commonSlice/dataGridCounts/dataGridCountsSlice';
import {
  setStudentType,
  setInstructor,
  setCourse,
  setGradeLevel,
  resetStudentProfileFilter,
} from 'layouts/studentManagement/store/studentProfileFilterSlice/studentProfileFilterSlice';
import {
  setStudentProfilePagination,
  setStudentProfilePaginationPreviousKey,
  resetStudentProfilePagination,
} from 'layouts/studentManagement/store/studentProfileSlice/studentProfilePaginationSlice';
import StudentProfileCourseDialog from './StudentProfileCourseDialog';
import { FormInputAutoCompleteSearch } from 'components/Common/Forms/FormAutoCompleteSearch';
import {
  getSearchedStudents,
  getSearchStudentDetails,
} from 'layouts/students/service/studentsApi';
import { getStudentData } from 'layouts/students/store/studentDetailsSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const Students = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  } = useNotification();
  const [openStudentProfileCourseDialog, setOpenStudentProfileCourseDialog] =
    useState(false);
  const [studentProfileCourses, setOpenStudentProfileCourses] = useState(null);
  const [studentsPageKey, setStudentsPageKey] = useState('');
  const [studentOptions, setStudentOptions] = useState([]);
  const [studentSearchQuery, setStudentSearchQuery] = useState('');
  const [isStudentSearchloading, setIsStudentSearchloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [studentList, setStudentList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
    total: 3000,
    currentPaginationKey: '',
    nextPaginationKey: '',
    count: '',
  });
  const { pageSize, page, nextPaginationKey, count } = studentList;

  const studentProfileFilterOptions = useSelector(
    (state) => state.studentProfileFilterOptions
  );

  const { allStudentTypes, allInstructors, allCourseNames, allGradeLevels } =
    studentProfileFilterOptions ?? [];

  const allStudentTypesOptions = allStudentTypes?.map((item) => ({
    id: item,
    value: item,
  }));
  const allInstructorsOptions = allInstructors?.map((item) => ({
    id: item,
    value: item,
  }));
  const allCourseNamesOptions = allCourseNames?.map((item) => ({
    id: item,
    value: item,
  }));
  const allGradeLevelsOptions = allGradeLevels?.map((item) => ({
    id: item,
    value: item,
  }));

  const dataGridCounts = useSelector(
    (state) => state?.totalDataGridCounts?.dataGridCounts
  );
  const totalNumber = dataGridCounts?.reduce((acc, curr) => acc + curr, 0);

  const studentProfilePagination = useSelector(
    (state) => state?.studentProfilePaginationKeyList?.studentProfilePagination
  );

  const studentProfileFilters = useSelector(
    (state) => state.studentProfileFilters
  );

  const { studentType, instructor, course, gradeLevel } = studentProfileFilters;

  useEffect(() => {
    if (page === 1) {
      dispatch(resetStudentProfilePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
    }
  }, []);

  useEffect(() => {
    getStudentProfileFilterOptionsData();
  }, []);

  useEffect(() => {
    fetchStudents();
  }, [pageSize, studentsPageKey, studentType, instructor, course, gradeLevel]);

  const getStudentProfileFilterOptionsData = async () => {
    try {
      const studentProfileFilterOptionsData =
        await getStudentProfileFilterOptions();
      dispatch(setStudentProfileFilterOptions(studentProfileFilterOptionsData));
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const defaultValue = {
    studentType: studentType,
    instructor: instructor,
    course: course,
    gradeLevel: gradeLevel,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  useEffect(() => {
    return () => {
      setStudentList((prevState) => ({
        ...prevState,
        rows: [],
      }));
      dispatch(resetStudentProfileFilter());
      dispatch(resetStudentProfileFilterOptions());
    };
  }, []);

  const fetchStudents = async () => {
    setStudentList({ ...studentList, isLoading: true });
    try {
      const studentsListData = await getStudentsProfile(
        pageSize,
        studentsPageKey,
        studentType,
        instructor,
        course,
        gradeLevel
      );
      setStudentList({ ...studentList, isLoading: false });
      setStudentList({
        ...studentList,
        currentPaginationKey: studentsListData?.currentPaginationKey,
        nextPaginationKey: studentsListData?.nextPaginationKey,
        count: studentsListData?.count,
      });
      setStudentList((prev) => ({
        ...prev,
        rows: getStudentProfileListData(studentsListData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
      setStudentList({ ...studentList, isLoading: false });
    }
  };

  useEffect(() => {
    if (!studentList?.isLoading) {
      if (count < pageSize) {
        setStudentList((prveState) => ({
          ...prveState,
          total: totalNumber,
        }));
      }
    }
  }, [count, pageSize]);

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.studentType,
    defaultValue.instructor,
    defaultValue.course,
    defaultValue.gradeLevel,
  ]);

  const redirect = () => {
    dispatch(resetStudentProfilePagination());
    dispatch(resetDataGridCounts());
    dispatch(setDataGridCounts(pageSize));
    setStudentList((prveState) => ({
      ...prveState,
      page: 1,
      total: 3000,
    }));
    setStudentsPageKey('');
  };

  const handleStudentType = (e) => {
    dispatch(setStudentType(e.target.value));
    redirect();
  };

  const handleInstructor = (e) => {
    dispatch(setInstructor(e.target.value));
    redirect();
  };

  const handleCourse = (e) => {
    dispatch(setCourse(e.target.value));
    redirect();
  };

  const handleGradeLevel = (e) => {
    dispatch(setGradeLevel(e.target.value));
    redirect();
  };

  const handelPageChange = (page) => {
    setStudentList((prveState) => ({
      ...prveState,
      page: page + 1,
    }));
    const paginationIdValue = {
      id: page,
      value: nextPaginationKey,
    };
    if (studentProfilePagination?.find((item) => item.id === page)) {
      const getPreviousPaginationKey = () => {
        const getPaginationKey = studentProfilePagination?.find(
          (item) => item.id === page
        );
        return getPaginationKey?.value;
      };
      const previousPaginationKey = getPreviousPaginationKey();
      dispatch(setStudentProfilePaginationPreviousKey(previousPaginationKey));
      setStudentsPageKey(previousPaginationKey);
    } else {
      dispatch(setDataGridCounts(count));
      dispatch(setStudentProfilePagination(paginationIdValue));
      setStudentsPageKey(nextPaginationKey);
    }
    if (page < 1) {
      setStudentList((prveState) => ({
        ...prveState,
        total: 3000,
      }));
      dispatch(resetStudentProfilePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
      setStudentsPageKey('');
    }
  };

  const handleOnPageSizeChange = (newPageSize) => {
    if (count < pageSize) {
      setStudentList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: totalNumber,
      }));
    } else {
      setStudentList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: 3000,
      }));
      dispatch(resetStudentProfilePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(newPageSize));
    }
  };

  const handleStudentCourses = async (params) => {
    setOpenStudentProfileCourseDialog(true);
    setOpenStudentProfileCourses(params?.row?.coursesData);
  };

  useEffect(() => {
    fetchSearchedStudents();
  }, [studentSearchQuery]);

  const fetchSearchedStudents = async () => {
    try {
      if (studentSearchQuery?.length >= 1) {
        setIsStudentSearchloading(true);
        const searchedStudents = await getSearchedStudents(
          studentSearchQuery,
          true,
          true,
          20
        );
        setStudentOptions(searchedStudents?.items);
        setIsStudentSearchloading(false);
      }
    } catch (error) {
      setIsStudentSearchloading(false);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const handleClearFilters = () => {
    dispatch(resetStudentProfileFilter());
  };

  const handleSearchStudentChange = (value) => {
    setStudentSearchQuery(
      value ? `${value.firstName} ${value.lastName} ${value.studentId}` : ''
    );

    if (value) {
      onRedirect(
        setIsLoading,
        getSearchStudentDetails,
        value.studentId,
        dispatch,
        getStudentData,
        navigate,
        '/student-management/student-search/student-profile',
        setOpenNotification,
        setNotificationMessage
      );
    }
  };

  const handleClearSearchStudents = () => {
    if (studentOptions.length === 0 && studentSearchQuery !== '') {
      return;
    }
    setStudentSearchQuery('');
    // setSelectedStudent(null);
  };

  const handleStudentRedirect = async (studentId) => {
    onRedirect(
      setIsLoading,
      getSearchStudentDetails,
      studentId,
      dispatch,
      getStudentData,
      navigate,
      '/student-management/student-search/student-profile',
      setOpenNotification,
      setNotificationMessage
    );
  };

  if (isLoading) {
    return showLoader();
  }

  const sorting = {
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
  };

  return (
    <>
      <CustomizedSnackbars />
      <MDBox>
        <Card>
          {getDataGridHeading('Search Students')}
          <GridWrapper container spacing={2}>
            <Grid item xs={12}>
              <FormInputAutoCompleteSearch
                label="Search students"
                placeholder="Search students"
                options={studentOptions}
                setSelectedInput={handleSearchStudentChange}
                setInputSearchQuery={setStudentSearchQuery}
                isSearchloading={isStudentSearchloading}
                onBlur={handleClearSearchStudents}
                getOptionLabel={getOptionLabel}
              />
            </Grid>
          </GridWrapper>
          <GridWrapper container spacing={2}>
            <Grid item xs={12} sm={6} md={2.4}>
              <FormInputDropdown
                name="studentType"
                type="text"
                control={control}
                options={allStudentTypesOptions}
                defaultValue={defaultValue?.studentType}
                label="Student Type"
                disabled={Boolean(studentSearchQuery)}
                register={register('studentType', {
                  onChange: (e) => {
                    handleStudentType(e);
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <FormInputDropdown
                name="instructor"
                type="text"
                control={control}
                options={allInstructorsOptions}
                defaultValue={defaultValue?.instructor}
                label="Instructor"
                disabled={Boolean(studentSearchQuery)}
                register={register('instructor', {
                  onChange: (e) => {
                    handleInstructor(e);
                  },
                })}
                filter
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <FormInputDropdown
                name="course"
                type="text"
                control={control}
                options={allCourseNamesOptions}
                defaultValue={defaultValue?.course}
                label="Course"
                disabled={Boolean(studentSearchQuery)}
                register={register('course', {
                  onChange: (e) => {
                    handleCourse(e);
                  },
                })}
                filter
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <FormInputDropdown
                name="gradeLevel"
                type="text"
                control={control}
                options={allGradeLevelsOptions}
                defaultValue={defaultValue?.gradeLevel}
                label="Grade Level"
                disabled={Boolean(studentSearchQuery)}
                register={register('gradeLevel', {
                  onChange: (e) => {
                    handleGradeLevel(e);
                  },
                })}
                filter
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              {getButtonSpinner(
                false,
                handleClearFilters,
                'Clear Filters',
                Boolean(studentSearchQuery)
              )}
            </Grid>
          </GridWrapper>
          <MDBox mt={2}></MDBox>
          <DeltaDataGrid
            listData={studentList}
            columns={studentColumns(
              handleStudentRedirect,
              handleStudentCourses
            )}
            handelPageChange={handelPageChange}
            setListData={handleOnPageSizeChange}
            defaultSortField="lastName"
          />
        </Card>
      </MDBox>
      <StudentProfileCourseDialog
        open={openStudentProfileCourseDialog}
        setOpen={setOpenStudentProfileCourseDialog}
        studentProfileCourses={studentProfileCourses}
        setOpenStudentProfileCourses={setOpenStudentProfileCourses}
      />
      <NotificationPopup />
    </>
  );
};

export default Students;
