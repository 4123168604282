import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CategoryList,
  CategoryColor,
  CategoryText,
  DeleteIconStyle,
  WhiteTooltip,
} from 'components/Ui/styled';
import { updateCalenderCategory } from 'layouts/applications/calendar/components/Categories/store/calenderCategorySlice';
import { deleteCategory, getCategoryId } from '../services/categoryApi';
import { Grid } from '@mui/material';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { useSelector } from 'react-redux';
import { useCalendarPermissionCustomHook } from 'layouts/applications/calenderAdmin/utils/calendarUtils';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';
import { truncateText } from 'utils/commonUtils';
import { useNotification } from 'utils/commonUtils';

const Category = (props) => {
  const dispatch = useDispatch();

  const {
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  } = useNotification();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const updateCategory = useCalendarPermissionCustomHook('updateCategory');
  const deleteCategoryAccess =
    useCalendarPermissionCustomHook('deleteCategory');

  const currentCalenderId = useSelector(
    (state) => state?.Calender?.selectCalendar
  );

  const yearMonth = useSelector((state) => state.yearMonth);

  const current = new Date(yearMonth);
  const getCurrentYear = `${current.getFullYear()}`;
  const getCurrentMonth = `${current.getMonth() + 1}`;

  const {
    fetchCalendarDataOfParticularMonth,
    calendarCategory,
    setOpenCategoryDialog,
    categories,
  } = props;

  const { calendarId, color, name, categoryId } = calendarCategory;
  const handleUpdateCategory = async () => {
    try {
      const { category } = await getCategoryId(categoryId, calendarId);
      dispatch(updateCalenderCategory({ ...category, categories }));
      setOpenCategoryDialog(true);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const categoriesData = {
    categoryId,
  };

  return (
    <>
      <CustomizedSnackbars />
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid
          data-testid="updateButton"
          onClick={updateCategory ? handleUpdateCategory : undefined}
        >
          <Grid>
            <CategoryList>
              <CategoryColor color={color} />
              <WhiteTooltip title={name}>
                <CategoryText variant="h6">{truncateText(name)}</CategoryText>
              </WhiteTooltip>
            </CategoryList>
          </Grid>
        </Grid>
        {deleteCategoryAccess && (
          <DeleteIconStyle
            onClick={() => setOpenDeleteDialog(true)}
          ></DeleteIconStyle>
        )}
      </Grid>
      <CommonDeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        contentText={
          'Are you sure you want to delete this Calendar category ' + name + '?'
        }
        fetchData={fetchCalendarDataOfParticularMonth}
        getCurrentYear={getCurrentYear}
        getCurrentMonth={getCurrentMonth}
        snakBarName="Calendar Category"
        commonDeleteApi={deleteCategory}
        commonDeleteId={categoriesData}
        currentCalenderId={currentCalenderId}
      />
      <NotificationPopup />
    </>
  );
};
export default Category;
