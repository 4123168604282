import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import { intialPageSize } from 'constants';
import { useNavigate } from 'react-router-dom';
import {
  getDataGridHeading,
  useListDialog,
  pageSizeChangeHandler,
  useNotification,
} from 'utils/commonUtils';
import { getAttendanceAudits } from './services/attendanceAuditApi';
import { renderAttendanceAuditAction } from './AttendanceAuditAction';
import { getAttendanceAuditReportId } from './store/attendanceAuditReportIdSlice';
import { TypographyLink } from 'components/Ui/styled';
import { getRenderedList } from 'utils/commonSlice/renderListSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const AttendanceAuditList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { NotificationPopup, handleErrorResponse } = useNotification();

  const { setDialogName, setOpenViewDialog, ListDialog } = useListDialog();
  const [attendanceAuditList, setAttendanceAuditList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchAttendanceAudits();
  }, []);

  const fetchAttendanceAudits = async () => {
    try {
      setAttendanceAuditList({ ...attendanceAuditList, isLoading: true });
      const attendanceAudits = await getAttendanceAudits();
      setAttendanceAuditList({ ...attendanceAuditList, isLoading: false });
      setAttendanceAuditList((previous) => ({
        ...previous,
        rows: attendanceAudits?.items.map((item, index) => ({
          ...item,
          id: item.id,
          name: item.name,
          reportType: item.reportParams.reportType,
          semesters: item.reportParams.semesters,
          students: item.reportParams.studentIds?.length,
          studentList: item.reportParams.studentIds,
        })),
      }));
    } catch (error) {
      setAttendanceAuditList({ ...attendanceAuditList, isLoading: false });
      handleErrorResponse(
        error?.response?.data?.message ||
          'An error occurred while fetching Attendance Audits'
      );
    }
  };

  const handleViewAttendanceAudit = async (params) => {
    const { row } = params;
    navigate('/applications/attendance-audit-report');
    dispatch(getAttendanceAuditReportId(row.id));
  };

  const handleStudents = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.studentList));
    setDialogName('Students');
    setOpenViewDialog(true);
  };

  const columns = [
    {
      field: 'action',
      headerName: 'View Report',
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      align: 'center',
      renderCell: (params) =>
        renderAttendanceAuditAction(params, handleViewAttendanceAudit),
    },
    { headerName: 'Name', field: 'name', flex: 1 },
    { headerName: 'Report Type', field: 'reportType', flex: 1 },
    {
      headerName: 'Students',
      field: 'students',
      renderCell: (params) => {
        return (
          <TypographyLink variant="h6" onClick={() => handleStudents(params)}>
            {params.row.students}
          </TypographyLink>
        );
      },
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      width: 140,
    },
    { headerName: 'Semester', field: 'semesters', flex: 1 },
  ];

  const pageSizeChange = pageSizeChangeHandler(setAttendanceAuditList);

  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading('Attendance Audits')}
          <DeltaDataGrid
            listData={attendanceAuditList}
            columns={columns}
            setListData={pageSizeChange}
          />
        </Card>
      </MDBox>
      <ListDialog />
      <NotificationPopup />
    </>
  );
};

export default AttendanceAuditList;
